import React from 'react'

const initialState = {
  liveChatApi: null,
  liveChatAvailable: false,
}

const actions = {
  SET_LIVECHATAPI: 'SET_LIVECHATAPI',
  SET_LIVECHATAVAILABLE: 'SET_LIVECHATAVAILABLE',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_LIVECHATAPI:
      return { ...state, liveChatApi: action.value }
      case actions.SET_LIVECHATAVAILABLE:
        return { ...state, liveChatAvailable: action.value }
    default:
      return state
  }
}

const LiveChatContext = React.createContext(initialState)

export const LiveChatProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)  

  const value = {
    liveChatApi: state.liveChatApi,
    liveChatAvailable: state.liveChatAvailable,
    setLiveChatApi: (value) => {
      dispatch({ type: actions.SET_LIVECHATAPI, value })
    },
    setLiveChatAvailable: (value) => {
      dispatch({ type: actions.SET_LIVECHATAVAILABLE, value })
    },
  }

  return <LiveChatContext.Provider value={value}>{children}</LiveChatContext.Provider>
}

export default LiveChatContext
