import { BaseApiRoute, jsonApiPostRequest } from './baseApiService'
import { logTrace } from './loggingService'

export function start(configuratorType, language, currency, requestId) {
  //logTrace('Sending Configurator Start request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/start`, {
    configuratorType: configuratorType,
    requestId: requestId,
    language: language,
    currency: currency,
  })
}

export function startGeneric(product, language, configState, currency, requestId) {
  //logTrace('Sending Configurator Start request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/startGeneric`, {
    product: product,
    requestId: requestId,
    configState: configState,
    language: language,
    currency: currency,
  })
}

export function startValveIslandConfiguratorFromPartNumber(
  partNumber,
  language,
  configuratorType,
  currency,
  requestId
) {
  //logTrace('Sending Configurator Start From PartNumber request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/startFromPartNumber`, {
    partNumber: partNumber,
    configuratorType: configuratorType,
    requestId: requestId,
    language: language,
    currency: currency
  })
}

export function commit(
  name,
  value,
  configState,
  language,
  configuratorType,
  currentStep,
  currency,
  requestId
) {
  //logTrace('Sending Configurator Commit request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/commit`, {
    name: name,
    value: value,
    configState: configState,
    requestId: requestId,
    language: language,
    configuratorType: configuratorType,
    currentStep: currentStep,
    currency: currency,
  })
}

export function commitMultiple(
  parameterValuePairs,
  configState,
  language,
  configuratorType,
  currentStep,
  currency,
  requestId
) {
  //logTrace('Sending Configurator Commit request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/commitMultiple`, {
    parameterValuePairs: parameterValuePairs,
    configState: configState,
    requestId: requestId,
    language: language,
    configuratorType: configuratorType,
    currentStep: currentStep,
    currency: currency,
  })
}

export function uncommit(name, configState, language, requestId) {
  //logTrace('Sending Configurator Uncommit request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/uncommit`, {
    name: name,
    configState: configState,
    requestId: requestId,
    language: language,
  })
}

export function accept(
  name,
  value,
  configState,
  language,
  configuratorType,
  currentStep,
  currency,
  requestId
) {
  //logTrace('Sending Configurator Accept request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/accept`, {
    name: name,
    value: value,
    configState: configState,
    requestId: requestId,
    language: language,
    configuratorType: configuratorType,
    currentStep: currentStep,
    currency: currency,
  })
}

export function moveStep(
  configState,
  currentStep,
  amountOfStepsToMove,
  language,
  configuratorType,
  currency,
  requestId
) {
  //logTrace('Sending Step request. Request ID: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/step`, {
    currentStep: currentStep,
    amountOfStepsToMove: amountOfStepsToMove,
    configState: configState,
    requestId: requestId,
    language: language,
    currency: currency,
    configuratorType: configuratorType,
  })
}

export function repeatValuesForAllStations(
  configState,
  currentStep,
  stationName,
  language,
  configuratorType,
  currency,
  requestId
) {
  //logTrace('Sending RepeatForAllStations request. Request ID: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/repeatForAllStations`, {
    configState: configState,
    currentStep: currentStep,
    stationName: stationName,
    language: language,
    configuratorType: configuratorType,
    requestId: requestId,
    currency: currency,
  })
}

export function getBOMSummary(configState, configuratorType, language, currency, omitUnits, requestId) {
  //logTrace('Sending Configurator BOM request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/bomsummary`, {
    configState: configState,
    configuratorType: configuratorType,
    requestId: requestId,
    language: language,
    currency: currency,
    omitUnits: omitUnits,
  })
}

export function requestAQuote(configState, requestId, key, requestAQuoteObject) {
  //logTrace('Sending Request A Quote Request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/requestAQuote`, {
    configState: configState,
    requestId: requestId,
    requestAQuote: requestAQuoteObject,
  })
}

export function addConfiguredProductToBasket(
  basketId,
  configState,
  quantity,
  country,
  currency,
  requestId,
  basketContext,
  address,
  name,
  configuratorType,
  language
) {
  //logTrace('Sending AddProductToBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/addToBasket`, {
    basketId: basketId,
    configState: configState,
    quantity: quantity,
    locale: country,
    currency: currency,
    requestId: requestId,
    basketContext: basketContext,
    address: address,
    name: name,
    configuratorType: configuratorType,
    language,
  })
}

export function getConfiguratorVersion(requestId) {
  return jsonApiPostRequest(`${BaseApiRoute}configurator/version`, {
    requestId: requestId,
  })
}
