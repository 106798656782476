import { Buffer } from 'buffer';
import { deleteCookie, getCookie, setCookie } from './cookieService'

const checkLocalStorageAvailable = () => {
  var testLocalStorage = 'testLocalStorage'
  try {
    localStorage.setItem(testLocalStorage, testLocalStorage)
    localStorage.removeItem(testLocalStorage)
    return true
  } catch (e) {
    return false
  }
}
const localStorageAvailable = checkLocalStorageAvailable()
const isLocalStorageAvailable = function () {
  return localStorageAvailable
}
export const setInsecureValue = (key, value) => {
  if (isLocalStorageAvailable()) {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem(key, jsonValue)
  } else {
    const base64Json = Buffer.from(JSON.stringify(value)).toString('base64')
    setCookie(key, base64Json)
  }
}
export const getInsecureValue = (key) => {
  if (isLocalStorageAvailable()) {
    const temp1 = localStorage.getItem(key)
    if (temp1) {
      return JSON.parse(temp1)
    } else {
      return null
    }
  } else {
    const temp2 = getCookie(key)
    if (temp2) {
      const json = Buffer.from(temp2, 'base64')
      return JSON.parse(json)
    } else {
      return null
    }
  }
}
export const removeInsecureValue = (key) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key)
  } else {
    const temp2 = getCookie(key)   
    if (temp2) {
      deleteCookie(key)
    }
  }
}