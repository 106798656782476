// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'

const reactPlugin = new ReactPlugin()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.GATSBY_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
})

if (process.env.GATSBY_APPINSIGHTS_KEY) {
  ai.loadAppInsights()
} else {
  //console.log('Application insights key not available.')
}

let component = (Component) => withAITracking(reactPlugin, Component)

export default component
export const appInsights = ai.appInsights
