import { appInsights } from './appInsightsService'
import { SeverityLevel } from '@microsoft/applicationinsights-web'

export function logException(message, error) {
  const errorJson = JSON.stringify(error, replacer)
  appInsights.trackException({
    error: `${message} ${errorJson}`,
    severityLevel: SeverityLevel.Error,
  })

  if (
    process.env.GATSBY_FEATURE_ENVIRONMENT === 'dev' ||
    process.env.GATSBY_FEATURE_ENVIRONMENT === 'test'
  ) {
    console.log(`ERROR: ${message} ${errorJson}`)
  }
}

export function logTrace(message) {
  appInsights.trackTrace({
    message: message,
    severityLevel: SeverityLevel.Information,
  })

  if (
    process.env.GATSBY_FEATURE_ENVIRONMENT === 'dev' ||
    process.env.GATSBY_FEATURE_ENVIRONMENT === 'test'
  ) {
    console.log(`INFO: ${message}`)
  }
}

export function replacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    }
  } else {
    return value
  }
}
