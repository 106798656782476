import { getInsecureValue, setInsecureValue } from './insecureStore'

export const DefaultChartLineColour = '#B8182C'
export const ProductCodeQueryParamName = 'productCode'

export const getConfiguratorState = (configuratorType, version) => {
  let configuratorState = getInsecureValue(configuratorType)

  if (configuratorState !== undefined && configuratorState !== null) {
    if (configuratorState.version !== version) {
      configuratorState = null
    }
  }

  return configuratorState
}

export const setConfiguratorState = (
  configuratorType,
  configState,
  responseModel,
  version,
  range,
  product,
  locale
) => {
  setInsecureValue(configuratorType, {
    configState: configState,
    responseModel: responseModel,
    range: range,
    version: version,
    product: product,
    locale: locale
  })
}

export const updateWarningMessages = (warningMessages, data) => {
  data.steps.forEach((element) => {
    if (element.current === true) {
      element.rootGroup.members[0].members.forEach((member) => {
        if (
          member.properties &&
          member.properties.tc_gui_type &&
          member.properties.tc_gui_type === 'static_message'
        ) {
          if (member.properties.hidden === 'no') {
            warningMessages[member.name] = member.description
          } else if (member.properties.hidden === 'yes') {
            delete warningMessages[member.name]
          }
        }
      })
    }
  })

  return warningMessages
}

export const ConvertToBackendShortCodeType = (configuratorType) => {
  switch (configuratorType) {
    case 'FRL':
      return 1
    case 'VS':
    case 'VM':
    case 'VRQ':
    case 'VR':
      return 0

    case 'Generic':
      return 2

    case 'Elion':
      return 3

    default:
      break
  }
}
