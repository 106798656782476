import { getInsecureValue, setInsecureValue } from './insecureStore'

export const languageKey = 'language'
export const defaultLanguageCode = 'en'

export function getLanguage(validLanguageCodes) {
  let language = getLanguageFromUrl(validLanguageCodes)

  if (language === undefined || language === null) {
    language = getInsecureValue(languageKey)

    if (language === undefined || language === null) {
      language = getBrowserLanguage(validLanguageCodes)
    }

    if (language === undefined || language === null) {
      language = defaultLanguageCode
    }
  }

  return language
}

export function setLanguage(language) {
  return setInsecureValue(languageKey, language)
}

function getLanguageFromUrl(validLanguageCodes) {
  const languageInUrl =
    typeof window !== 'undefined' ? window.location.pathname.substring(0, 4) : ''
  let language = undefined

  if (languageInUrl.startsWith('/') && languageInUrl.endsWith('/')) {
    language = languageInUrl.substring(1, 3)
    if (isLangaugeValid(validLanguageCodes, language) === false) {
      language = undefined
    }
  }

  return language
}

function getBrowserLanguage(validLanguageCodes) {
  const languages = typeof navigator !== 'undefined' ? navigator.languages : undefined
  let language = undefined

  if (languages !== null && languages !== undefined && languages.length > 0) {
    language = languages[0].substring(0, 2)
    if (isLangaugeValid(validLanguageCodes, language) === false) {
      language = undefined
    }
  }

  return language
}

function isLangaugeValid(validLanguageCodes, language) {
  return validLanguageCodes[language] !== undefined
}
