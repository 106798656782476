import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  accept,
  commit,
  commitMultiple,
  moveStep,
  repeatValuesForAllStations,
  start,
  startGeneric,
} from '../services/configuratorApiService'
import { getConfiguratorState, setConfiguratorState } from '../services/configuratorService'

const initialState = {
  loading: true,
  responseModel: null,
  currentStep: null,
  configuratorType: null,
  version: null,
  product: null,
  unavailable: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      let newState = { ...state, loading: action.value }
      return newState
    }
    case actions.SET_RESPONSEMODEL: {
      let newState = { ...state, responseModel: action.value }
      return newState
    }
    case actions.SET_CURRENTSTEP: {
      let newState = { ...state, currentStep: action.value }
      return newState
    }
    case actions.SET_CONFIGURATORTYPE: {
      let newState = { ...state, configuratorType: action.value }
      return newState
    }
    case actions.SET_VERSION: {
      let newState = { ...state, version: action.value }
      return newState
    }
    case actions.SET_PRODUCT: {
      let newState = { ...state, product: action.value }
      return newState
    }
    case actions.SET_UNAVAILABLE: {
      let newState = { ...state, unavailable: action.value }
      return newState
    }
    default:
      return state
  }
}

const actions = {
  SET_LOADING: 'SET_LOADING',
  SET_RESPONSEMODEL: 'SET_RESPONSEMODEL',
  SET_CURRENTSTEP: 'SET_CURRENTSTEP',
  SET_CONFIGURATORTYPE: 'SET_CONFIGURATORTYPE',
  SET_VERSION: 'SET_VERSION',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_UNAVAILABLE: 'SET_UNAVAILABLE',
}

const ConfiguratorContext = React.createContext(initialState)

export const ConfiguratorProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    loading: state.loading,
    responseModel: state.responseModel,
    currentStep: state.currentStep,
    configuratorType: state.configuratorType,
    version: state.version,
    product: state.product,
    unavailable: state.unavailable,
    setLoading: (value) => {
      dispatch({ type: actions.SET_LOADING, value })
    },
    setResponseModel: (value) => {
      dispatch({ type: actions.SET_RESPONSEMODEL, value })
    },
    setCurrentStep: (value) => {
      dispatch({ type: actions.SET_CURRENTSTEP, value })
    },
    setConfiguratorType: (value) => {
      dispatch({ type: actions.SET_CONFIGURATORTYPE, value })
    },
    setVersion: (value) => {
      dispatch({ type: actions.SET_VERSION, value })
    },
    setProduct: (value) => {
      dispatch({ type: actions.SET_PRODUCT, value })
    },
    setUnavailable: (value) => {
      dispatch({ type: actions.SET_UNAVAILABLE, value })
    },
  }

  return <ConfiguratorContext.Provider value={value}>{children}</ConfiguratorContext.Provider>
}

export function commitValue(context, name, value, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  return new Promise((resolve, reject) => {
    commit(
      name,
      value,
      context.responseModel.configState,
      locale,
      context.configuratorType,
      context.currentStep,
      currency,
      requestId
    )
      .then((data) => {
        const state = getConfiguratorState(context.configuratorType, context.version)
        const myConfiguratorType = context.configuratorType?.startsWith(data.type)
          ? context.configuratorType
          : data.type
  
        setConfiguratorState(
          myConfiguratorType,
          data.configState,
          data,
          context.version,
          data.range ?? state?.range,
          null,
          locale
        )
  
        context.setResponseModel(data)
        context.setCurrentStep(data.currentStep)
        context.setUnavailable(false)
        context.setLoading(false)
        resolve()
      })
      .catch((err) => {
        console.log(err)
        context.setLoading(false)
        reject()
      })
  })
}

export function commitValues(context, valuePairs, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  return new Promise((resolve, reject) => {
    commitMultiple(
      valuePairs,
      context.responseModel.configState,
      locale,
      context.configuratorType,
      context.currentStep,
      currency,
      requestId
    )
      .then((data) => {
        const state = getConfiguratorState(context.configuratorType, context.version)
        const myConfiguratorType = context.configuratorType?.startsWith(data.type)
          ? context.configuratorType
          : data.type
  
        setConfiguratorState(
          myConfiguratorType,
          data.configState,
          data,
          context.version,
          data.range ?? state?.range,
          null,
          locale
        )
  
        context.setResponseModel(data)
        context.setCurrentStep(data.currentStep)
        context.setUnavailable(false)
        context.setLoading(false)
        resolve()
      })
      .catch((err) => {
        console.log(err)
        context.setLoading(false)
        reject()
      })
  })
}

export function startConfigurator(context, configuratorType, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  start(configuratorType, locale, currency, requestId)
    .then((data) => {
      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)
      context.setConfiguratorType(configuratorType)

      const state = getConfiguratorState(context.configuratorType, context.version)
      const myConfiguratorType = context.configuratorType?.startsWith(data.type)
        ? context.configuratorType
        : data.type

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        null,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
      context.setUnavailable(true)
    })
}

export function startGenericConfigurator(
  context,
  configuratorType,
  productCode,
  configState,
  locale,
  currency
) {
  const requestId = uuid()
  context.setLoading(true)

  startGeneric(productCode, locale, configState, currency, requestId)
    .then((data) => {
      const myConfiguratorType = configuratorType.startsWith(data.type)
        ? configuratorType
        : data.type

      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)
      context.setConfiguratorType(configuratorType)

      const state = getConfiguratorState(myConfiguratorType, context.version)

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        null,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
      context.setUnavailable(true)
    })
}

export function step(context, amountOfStepsToMove, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  moveStep(
    context.responseModel.configState,
    context.currentStep,
    amountOfStepsToMove,
    locale,
    context.configuratorType,
    currency,
    requestId
  )
    .then((data) => {
      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)

      let product = null
      if (data.currentStep === 'SummaryStep') {
        product = data.productResponse.product
        context.setProduct(product)
      }

      const state = getConfiguratorState(context.configuratorType, context.version)
      const myConfiguratorType = context.configuratorType?.startsWith(data.type)
        ? context.configuratorType
        : data.type

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        product,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
    })
}

// used when context has not updated to contain configState etc and needs to be passed in directly
export function stepUsingConfigState(
  context,
  configState,
  currentStep,
  configuratorType,
  amountOfStepsToMove,
  locale,
  currency
) {
  const requestId = uuid()
  context.setLoading(true)

  moveStep(
    configState,
    currentStep,
    amountOfStepsToMove,
    locale,
    configuratorType,
    currency,
    requestId
  )
    .then((data) => {
      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)

      let product = null
      if (data.currentStep === 'SummaryStep') {
        product = data.productResponse.product
        context.setProduct(product)
      }

      const state = getConfiguratorState(context.configuratorType, context.version)
      const myConfiguratorType = context.configuratorType?.startsWith(data.type)
        ? context.configuratorType
        : data.type

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        product,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
    })
}

export function repeatForStations(context, stationName, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  repeatValuesForAllStations(
    context.responseModel.configState,
    context.currentStep,
    stationName,
    locale,
    context.configuratorType,
    currency,
    requestId
  )
    .then((data) => {
      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)

      const state = getConfiguratorState(context.configuratorType, context.version)
      const myConfiguratorType = context.configuratorType?.startsWith(data.type)
        ? context.configuratorType
        : data.type

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        null,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
    })
}

export function acceptConflict(context, name, value, locale, currency) {
  const requestId = uuid()
  context.setLoading(true)

  accept(
    name,
    value,
    context.responseModel.configState,
    locale,
    context.configuratorType,
    context.currentStep,
    currency,
    requestId
  )
    .then((data) => {
      context.setResponseModel(data)
      context.setCurrentStep(data.currentStep)
      context.setUnavailable(false)
      context.setLoading(false)

      const state = getConfiguratorState(context.configuratorType, context.version)
      const myConfiguratorType = context.configuratorType?.startsWith(data.type)
        ? context.configuratorType
        : data.type

      setConfiguratorState(
        myConfiguratorType,
        data.configState,
        data,
        context.version,
        data.range ?? state?.range,
        null,
        locale
      )
    })
    .catch((err) => {
      console.log(err)
      context.setLoading(false)
    })
}

export function getConfiguratorProductImageBaseUrl(productCode) {
  switch (productCode) {
    case 'VR':
    case 'VRQ':
    case 'VM':
    case 'VS':
      return 'vic'
    case 'FRL':
      return 'frlc'
    default:
      break
  }
}

export default ConfiguratorContext
