import React from 'react'

const initialState = {
  jobSubjects: {},
}

const actions = {
  ADD_JOB_SUBJECT: 'ADD_JOB_SUBJECT',
  REMOVE_JOB_SUBJECT: 'REMOVE_JOB_SUBJECT',
  REMOVE_ALL_JOB_SUBJECTS: 'REMOVE_ALL_JOB_SUBJECTS',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.ADD_JOB_SUBJECT: {
      let jobSubjects = state.jobSubjects
      let existing = jobSubjects[action.value.jobId]

      if (existing === undefined) {
        jobSubjects[action.value.jobId] = action.value
      }

      return { ...state, jobSubjects: { ...jobSubjects } }
    }
    case actions.REMOVE_JOB_SUBJECT: {
      let jobSubjects = state.jobSubjects
      let existing = state.jobSubjects[action.value.jobId]

      if (existing !== undefined) {
        delete state.jobSubjects[existing.jobId]
        existing.teardown()
      }

      return { ...state, jobSubjects: { ...jobSubjects } }
    }
    case actions.REMOVE_ALL_JOB_SUBJECTS: {
      for (const [key, value] of Object.entries(state.jobSubjects)) {
        value.teardown()
      }

      return { ...state, jobSubjects: {} }
    }
    default:
      return state
  }
}

const JobContext = React.createContext(initialState)

export const JobProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    jobSubjects: state.jobSubjects,
    addSubject: (value) => {
      dispatch({ type: actions.ADD_JOB_SUBJECT, value })
    },
    removeSubject: (value) => {
      dispatch({ type: actions.REMOVE_JOB_SUBJECT, value })
    },
    removeAllSubjects: (value) => {
      dispatch({ type: actions.REMOVE_ALL_JOB_SUBJECTS, value })
    },
  }

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>
}

export default JobContext

export function getSubjectForJob(jobSubjects, jobId) {
  for (const [key, value] of Object.entries(jobSubjects)) {
    if (jobId === key) {
      return value
    }
  }

  return undefined
}

export function detachFromJobs(jobSubjects, observer) {
  for (const [key, value] of Object.entries(jobSubjects)) {
    value.detach(observer)
  }
}
