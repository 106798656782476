import React from 'react'
import { useLanguages } from '../hooks/useLanguages'
import { getInsecureValue } from '../services/insecureStore'
import { defaultLanguageCode, getLanguage, languageKey, setLanguage } from '../services/languageService'

const initialState = {
  language: getInsecureValue(languageKey),
}

const actions = {
  SET_LANGUAGE: 'SET_LANGUAGE',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_LANGUAGE:
      return { ...state, language: action.value }
    default:
      return state
  }
}

const LanguageContext = React.createContext(initialState)

export const LanguageProvider = ({ children }) => {
  const validLanguageCodes = useLanguages(defaultLanguageCode).reduce((a, x) => ({ ...a, ['' + x.slug + '']: x.slug }), {})
  let language = getLanguage(validLanguageCodes)
  setLanguage(language)
  const [state, dispatch] = React.useReducer(reducer, {
    language: language,
  })  

  const value = {
    language: state.language,
    setLanguage: (value) => {
      dispatch({ type: actions.SET_LANGUAGE, value })
      setLanguage(value)
    },
  }

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

export default LanguageContext
