import React from 'react'
import { currentGeolocation } from '../services/geolocationService'

const initialState = {
  country: currentGeolocation ? currentGeolocation.country : undefined,
  callingCountry: currentGeolocation ? currentGeolocation.callingCountry : undefined,
  expires: currentGeolocation ? currentGeolocation.expires : undefined,
}

const actions = {
  SET_COUNTRY: 'SET_COUNTRY',
  SET_CALLINGCOUNTRY: 'SET_CALLINGCOUNTRY',
  SET_EXPIRES: 'SET_EXPIRES',
  SET_GEOLOCATION: 'SET_GEOLOCATION',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_COUNTRY: {
      let newState = { ...state, country: action.value }
      return newState
    }
    case actions.SET_CALLINGCOUNTRY: {
      let newState = { ...state, callingCountry: action.value }
      return newState
    }
    case actions.SET_EXPIRES: {
      let newState = { ...state, expires: action.value }
      return newState
    }
    case actions.SET_GEOLOCATION:
      return {
        ...state,
        country: action.value.country,
        callingCountry: action.value.callingCountry,
        expires: action.value.expires,
      }
    default:
      return state
  }
}

const GeolocationContext = React.createContext(initialState)

export const GeolocationProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    country: state.country,
    callingCountry: state.callingCountry,
    expires: state.expires,
    setCountry: (value) => {
      dispatch({ type: actions.SET_COUNTRY, value })
    },
    setCallingCountry: (value) => {
      dispatch({ type: actions.SET_CALLINGCOUNTRY, value })
    },
    setExpires: (value) => {
      dispatch({ type: actions.SET_EXPIRES, value })
    },
    setGeolocation: (value) => {
      dispatch({ type: actions.SET_GEOLOCATION, value })
    },
  }

  return <GeolocationContext.Provider value={value}>{children}</GeolocationContext.Provider>
}

export default GeolocationContext
