import React from 'react'

export function getI18nText(microcopy, id) {
  try {
    if (
      process.env.GATSBY_SHOW_I18N_ID !== undefined &&
      process.env.GATSBY_SHOW_I18N_ID !== null &&
      process.env.GATSBY_SHOW_I18N_ID === 'true'
    ) {
      return (
        <abbr style={{ textDecoration: 'none' }} title={id}>
          {microcopy[id].value}
        </abbr>
      )
    } else {
      return microcopy[id].value
    }
  } catch (error) {
    return id
  }
}

export function getI18nTextWithFallback(microcopy, id, fallback) {
  let text = getI18nText(microcopy, id)

  if (text === '' || (text === id && fallback)) {
    text = fallback
  }

  return text
}

export function getI18nTextWithFallbackId(microcopy, id, fallbackId) {
  let text = getI18nText(microcopy, id)

  if (text === '' || (text === id && fallbackId)) {
    const fallbackText = getI18nText(microcopy, fallbackId)

    if (fallbackText && fallbackText !== '' && fallbackText !== fallbackId) {
      text = fallbackText
    }
  }

  return text
}

export function convertToMicrocopyKey(value) {
  if (value) {
    value = formatSpecificMicrocopyKeys(value)
    value = value.replaceAll(' ', '')
    value = value.replaceAll('/', '')
    value = value.replaceAll('"', '')
    value = value.replaceAll('-', '')
    value = value.replaceAll('_', '')
    value = value.replaceAll('(', '')
    value = value.replaceAll(')', '')
    value = value.replaceAll(',', '')
    value = value.replaceAll('µ', '')
    value = value.replaceAll('⌀', '')
    value = value.replaceAll('°', '')
    value = value.replaceAll("'", '')
    value = value.replaceAll('+', '')
    value = value.replaceAll('/', '')
    value = value.replaceAll(',', '')
    value = value.replaceAll('&', '')
    // value = value.replaceAll('.', '')
    value = value.toLowerCase()
  }
  return value
}

function formatSpecificMicrocopyKeys(value) {
  if (value) {
    switch (value) {
      case '1/4"':
        value = value.concat('inch')
        break
      case '1/2"':
        value = value.concat('inch')
        break

      default:
        break
    }
  }
  return value
}

export function doesMicrocopyTextExist(microcopy, id) {
  if (microcopy[id] !== null && microcopy[id] !== undefined) {
    return true
  } else {
    return false
  }
}
