import { logTrace } from './loggingService'
import { isMobile } from 'react-device-detect'
import { BaseApiRoute, BaseRoute, jsonApiPostRequest } from './baseApiService'

export const BasketContextBrowsing = 0
export const BasketContextCheckout = 1
export const CertificateOfComformitySku = 'COC'
export const StandardOrderOrigin = 0
export const DataWarehouseOrderOrigin = 1
export const MaxBasketItemQuantity = 100000

export const defaultPricingDisplayOptions = {
  showDeliveryOnRequest: false,
  showLoginForPrice: false,
  showStockLevelIndicator: false,
  showRequestAQuote: true,
  showListPrice: false,
  showNetPrice: false,
  showDeliveryDate: false,
  showIsFallbackMessage: false,
  showPricingTiers: false,
  showDiscardConfiguration: false,
  showFindDistributor: false,
}

export function whoAmI(requestId) {
  //logTrace('Sending WhoAmI request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/whoami`, {
    requestId: requestId,
  })
}

export function getGeolocationCountry(requestId) {
  //logTrace('Sending getGeolocation request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/getGeolocation`, {
    requestId: requestId,
  })
}

export function getProducts(
  urlParams,
  friendlyFilterParams,
  callingCountry,
  viewSize,
  requestId,
  currentQueryParams,
  locale
) {
  //logTrace('Sending getProducts request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}products/getProducts`, {
    friendlyFilterParams: friendlyFilterParams,
    urlParams: urlParams,
    callingCountry: callingCountry,
    isoLocalDateTime: new Date().toISOString(),
    device: isMobile ? 'mobile' : 'desktop',
    viewSize: viewSize,
    requestId: requestId,
    currentQueryParams: currentQueryParams,
    language: locale,
  })
}

export function register(registerModel, recaptchaCode, requestId) {
  //logTrace('Sending Register request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/register`, {
    firstName: registerModel.firstname,
    lastName: registerModel.lastname,
    companyName: registerModel.companyname,
    countryCode: registerModel.country,
    currency: registerModel.currency,
    email: registerModel.email,
    confirmEmail: registerModel.confirmemail,
    telephone: registerModel.telephone,
    recaptchaCode: recaptchaCode,
    country: registerModel.country,
    language: registerModel.language,
    requestId: requestId,
  })
}

export function search(
  urlParams,
  extrabaseUrlParams,
  friendlyFilterParams,
  callingCountry,
  viewSize,
  requestId,
  currentQueryParams,
  locale
) {
  //logTrace('Sending Search request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}search`, {
    urlParams: urlParams,
    extrabaseUrlParams: extrabaseUrlParams,
    friendlyFilterParams: friendlyFilterParams,
    callingCountry: callingCountry,
    isoLocalDateTime: new Date().toISOString(),
    device: isMobile ? 'mobile' : 'desktop',
    viewSize: viewSize,
    requestId: requestId,
    currentQueryParams: currentQueryParams,
    language: locale,
  })
}

export function suggest(term, locale, callingCountry, requestId) {
  //logTrace('Sending Suggest request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}suggest`, {
    suggestTerm: term,
    language: locale,
    callingCountry: callingCountry,
    requestId: requestId,
  })
}

export function createBasket(currency, country, language, requestId) {
  //logTrace('Sending createBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/create`, {
    currency: currency,
    requestId: requestId,
    country: country,
    language: language,
  })
}

export function getBasket(basketId, currency, country, requestId, basketContext, language) {
  //logTrace('Sending getBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/get`, {
    basketId: basketId,
    currency: currency,
    country: country,
    requestId: requestId,
    basketContext: basketContext,
    language: language,
  })
}

export function addProductToBasket(
  basketId,
  itemSku,
  quantity,
  country,
  currency,
  requestId,
  basketContext,
  address,
  name,
  language
) {
  //logTrace('Sending AddProductToBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/addProduct`, {
    basketId: basketId,
    itemSku: itemSku,
    quantity: quantity,
    country: country,
    currency: currency,
    requestId: requestId,
    basketContext: basketContext,
    address: address,
    name: name,
    language: language,
  })
}

export function addMultipleProductsToBasketJob(products, country, currency, requestId, language) {
  //logTrace('Sending MultipleProductsToBasketJob request')
  return jsonApiPostRequest(`${BaseApiRoute}job/createAddMultipleProductsToBasketJob`, {
    basketProducts: products,
    country: country,
    currency: currency,
    requestId: requestId,
    language: language,
  })
}

export function addMultipleProductsToBasket(
  basketId,
  basketProducts,
  country,
  currency,
  requestId,
  basketContext,
  address,
  name,
  language
) {
  //logTrace('Sending AddProductToBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/addMultipleProducts`, {
    basketId: basketId,
    basketProducts: basketProducts,
    country: country,
    currency: currency,
    requestId: requestId,
    basketContext: basketContext,
    address: address,
    name: name,
    language: language,
  })
}

export function removeProductFromBasket(
  basketId,
  itemId,
  country,
  currency,
  requestId,
  basketContext,
  address,
  language
) {
  //logTrace('Sending RemoveProductFromBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/removeProduct`, {
    basketId: basketId,
    itemId: itemId,
    country: country,
    currency: currency,
    requestId: requestId,
    basketContext: basketContext,
    address: address,
    language: language,
  })
}

export function emptyBasket(basketId, country, currency, language, requestId) {
  //logTrace('Sending EmptyBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/emptyBasket`, {
    basketId: basketId,
    country: country,
    currency: currency,
    requestId: requestId,
    language: language,
  })
}

export function updateProduct(basketId, itemId, quantity, country, currency, language, requestId) {
  //logTrace('Sending UpdateProduct request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/updateProduct`, {
    basketId: basketId,
    itemId: itemId,
    quantity: quantity,
    country: country,
    currency: currency,
    requestId: requestId,
    language: language,
  })
}

export function applyPromoCode(basketId, promoCode, country, currency, language, requestId) {
  //logTrace('Sending ApplyPromoCode request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/applyPromoCode`, {
    basketId: basketId,
    promoCode: promoCode,
    country: country,
    currency: currency,
    requestId: requestId,
    language: language,
  })
}

export function applyDeliveryCharge(
  basketId,
  address,
  country,
  currency,
  deliveryChargeId,
  modeOfTransport,
  language,
  requestId
) {
  //logTrace('Sending ApplyDeliveryCharge request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/applyDeliveryCharge`, {
    basketId: basketId,
    address: address,
    country: country,
    currency: currency,
    deliveryChargeId: deliveryChargeId,
    deliveryModeOfTransport: modeOfTransport,
    language: language,
    requestId: requestId,
  })
}

export function getAdditionalDeliveryOptions(basketId, address, country, currency, requestId) {
  //logTrace('Sending getAdditionalDeliveryOptions request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/getAdditionalDeliveryOptions`, {
    basketId: basketId,
    address: address,
    country: country,
    currency: currency,
    requestId: requestId,
  })
}

export function removeDeliveryCharge(basketId, country, currency, language, requestId) {
  //logTrace('Sending RemoveDeliveryCharge request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/removeDeliveryCharge`, {
    basketId: basketId,
    country: country,
    currency: currency,
    language: language,
    requestId: requestId,
  })
}

export function checkoutBasket(
  basketId,
  contact,
  billingAddress,
  shippingAddress,
  payment,
  delivery,
  requestId
) {
  //logTrace('Sending CheckoutBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}basket/checkout`, {
    basketId: basketId,
    contact: contact,
    billingAddress: billingAddress,
    shippingAddress: shippingAddress,
    payment: payment,
    delivery: delivery,
    requestId: requestId,
  })
}

export function preparePayment(
  basketId,
  contact,
  billingAddress,
  shippingAddress,
  payment,
  delivery,
  successUrl,
  errorUrl,
  language,
  requestId
) {
  //logTrace('Sending Payment Prepare request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseRoute}payment/prepare`, {
    basketId: basketId,
    contact: contact,
    billingAddress: billingAddress,
    shippingAddress: shippingAddress,
    payment: payment,
    delivery: delivery,
    successUrl: successUrl,
    errorUrl: errorUrl,
    language: language,
    requestId: requestId,
  })
}

export function getProduct(productCode, country, currency, requestId) {
  //logTrace('Sending GetProduct request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}product/getProduct`, {
    productCode: productCode,
    currency: currency,
    country: country,
    requestId: requestId,
  })
}

export function getCertificateOfConformityProduct(country, currency, requestId) {
  //logTrace('Sending GetCertificateOfConformityProduct request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}product/getCertificateOfConformityProduct`, {
    currency: currency,
    country: country,
    requestId: requestId,
  })
}

export function getPriceForDetailPage(productCode, country, currency, language, requestId) {
  //logTrace('Sending getPriceForDetailPage request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}product/getPriceForDetailPage`, {
    productCode: productCode,
    currency: currency,
    country: country,
    language: language,
    requestId: requestId,
  })
}

export function getPriceForListerPage(productCode, country, currency, language, requestId) {
  //logTrace('Sending getPriceForListerPage request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}product/getPriceForListerPage`, {
    productCode: productCode,
    currency: currency,
    country: country,
    language: language,
    requestId: requestId,
  })
}

export function getPriceForAccessoriesPage(productCode, country, currency, language, requestId) {
  //logTrace('Sending getPriceForAccessoriesPage request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}product/getPriceForAccessoriesPage`, {
    productCode: productCode,
    currency: currency,
    country: country,
    language: language,
    requestId: requestId,
  })
}

export function getAccountAddresses(requestId) {
  //logTrace('Sending GetAccountAddresses request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/getAccountAddresses`, {
    requestId: requestId,
  })
}

export function getAccountCheckoutInformation(basketId, requestId) {
  //logTrace('Sending GetAccountCheckoutInformation request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/getAccountCheckoutInformation`, {
    basketId: basketId,
    requestId: requestId,
  })
}

export function addAccountAddress(address, requestId) {
  //logTrace('Sending AddAccountAddress request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/saveAccountAddress`, {
    address: address,
    requestId: requestId,
  })
}

export function deleteAccountAddress(addressId, requestId) {
  //logTrace('Sending deleteAccountAddress request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/deleteAccountAddress`, {
    addressId: addressId,
    requestId: requestId,
  })
}

export function changePassword(requestId) {
  //logTrace('Sending ChangePassword request. RequestId:' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/changePassword`, {
    requestId: requestId,
  })
}

export function getAllUserAccounts(requestId) {
  //logTrace('Sending GetAllUserAccounts request. RequestId:' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/getAllUserAccounts`, {
    requestId: requestId,
  })
}
export function getOrders(skip, take, orderOrigin, requestId) {
  //logTrace('Sending GetOrders request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}order/getPaged`, {
    skip: skip,
    take: take,
    orderOrigin: orderOrigin,
    requestId: requestId,
  })
}

export function changeAccount(accountId, requestId) {
  //logTrace('Sending ChangeAccount request. RequestId:' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/changeAccount`, {
    requestId: requestId,
    accountId: accountId,
  })
}

export function getOrder(orderId, orderOrigin, requestId) {
  //logTrace('Sending GetOrders request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}order/get`, {
    orderId: orderId,
    orderOrigin: orderOrigin,
    requestId: requestId,
  })
}

export function getOrderForCheckout(orderId, orderOrigin, requestId) {
  //logTrace('Sending GetOrders request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}order/getForCheckout`, {
    orderId: orderId,
    orderOrigin: orderOrigin,
    requestId: requestId,
  })
}

export function findAddress(text, container, country, requestId) {
  //logTrace('Sending FindAddress request. RequestID: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/findaddress`, {
    text: text,
    container: container,
    country: country,
    requestId: requestId,
  })
}

export function retrieveAddress(id, requestId) {
  //logTrace('Sending FindAddress request. RequestID: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}account/retrieveAddress`, {
    id: id,
    requestId: requestId,
  })
}

export function submitFormToSalesForce(data, submitUrl, requestId) {
  //logTrace('Sending submitFormToSalesForce request. Request: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}form/submitForm`, {
    requestId: requestId,
    formData: data,
    submitUrl: submitUrl,
  })
}

export function getSavedBasekts(requestId, country, currency, language) {
  //logTrace('Sending GetSavedBaskestsRequest. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/getSavedBaskets`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
  })
}

export function saveCurrentBasket(requestId, country, currency, language, basketId, basketName) {
  //logTrace('Sending SaveCurrentBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/saveCurrentBasket`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    basketId: basketId,
    basketName: basketName,
  })
}

export function replaceCurrentBasketWithSavedBasket(
  requestId,
  country,
  currency,
  language,
  savedBasketId
) {
  //logTrace('Sending SaveCurrentBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/replaceCurrentBasketWithSavedBasket`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    savedBasketId: savedBasketId,
  })
}

export function mergeCurrentBasketWithSavedBasket(
  requestId,
  country,
  currency,
  language,
  savedBasketId
) {
  //logTrace('Sending SaveCurrentBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/mergeCurrentBasketWithSavedBasketJob`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    savedBasketId: savedBasketId,
  })
}

export function addOrderItemsToBasketJob(requestId, country, currency, language, orderId) {
  //logTrace('Sending addOrderItemsToBasketJob request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/addOrderItemsToBasketJob`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    orderId: orderId,
  })
}

export function deleteSavedBasket(requestId, country, currency, language, basketId) {
  //logTrace('Sending DeleteSavedBasket request. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}savedbasket/deleteSavedBasket`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    basketId: basketId,
  })
}

export function submitForm(data, url) {
  const formBody = Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve({ status: response.status })
            break
          default:
            reject({ status: response.status })
            break
        }
      })
      .catch((error) => reject({ error: error }))
  })
}

export function getValidCurrencies(requestId) {
  //logTrace('Sending GetValidCurrencies request: Request Id: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}currency/getValidCurrencies`, {
    requestId: requestId,
  })
}

export function saveConfiguration(
  requestId,
  country,
  currency,
  language,
  shortCode,
  protocol,
  range,
  numberOfStations,
  description
) {
  //logTrace('Sending SaveConfiguration requeset: Request Id: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/saveConfiguration`, {
    country: country,
    requestId: requestId,
    currency: currency,
    language: language,
    shortCode: shortCode,
    protocol: protocol,
    range: range,
    numberOfStations: numberOfStations,
    description: description,
  })
}

export function getSavedConfigurations(requestId, country, currency, language) {
  //logTrace('Sending GetSavedConfigurations. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/getSavedConfigurations`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
  })
}

export function deleteSavedConfiguration(requestId, country, currency, language, configurationId) {
  //logTrace('Sending DeleteSavedConfigurations. RequestId: ' + requestId)
  return jsonApiPostRequest(`${BaseApiRoute}configurator/deleteSavedConfiguration`, {
    requestId: requestId,
    currency: currency,
    language: language,
    country: country,
    savedConfigurationId: configurationId,
  })
}

export function downloadInvoice(requestId, invoiceNumber, invoiceDate) {
  return jsonApiPostRequest(`${BaseApiRoute}order/downloadInvoice`, {
    requestId: requestId,
    invoiceNumber: invoiceNumber,
    invoiceDate: invoiceDate
  }, true)
}
