import React from 'react'

const initialState = {
  eventApi: null,
}

const actions = {
  SET_EVENTAPI: 'SET_EVENTAPI',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_EVENTAPI:
      return { ...state, eventApi: action.value }
    default:
      return state
  }
}

const DataLayerContext = React.createContext(initialState)

export const DataLayerProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)  

  const value = {
    eventApi: state.eventApi,
    setEventApi: (value) => {
      dispatch({ type: actions.SET_EVENTAPI, value })
    },
  }

  return <DataLayerContext.Provider value={value}>{children}</DataLayerContext.Provider>
}

export default DataLayerContext
