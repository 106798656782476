import React, { createContext } from 'react'

const initialState = {
  subNavId: undefined,
  isMobileNavOpen: false,
  contextTimeout: undefined
}

const actions = {
  SET_SUBNAVID: 'SET_SUBNAVID',
  SET_ISMOBILENAVOPEN: 'SET_ISMOBILENAVOPEN',
  SET_CONTEXTTIMEOUT: "SET_CONTEXTTIMEOUT"
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ISMOBILENAVOPEN:
      return { ...state, isMobileNavOpen: action.value }
    case actions.SET_SUBNAVID:
      return { ...state, subNavId: action.value }
      case actions.SET_CONTEXTTIMEOUT:
      return { ...state, contextTimeout: action.value }
    default:
      return state
  }
}

const MobileNavContext = createContext(initialState)

export const MobileNavProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    isMobileNavOpen: state.isMobileNavOpen,
    subNavId: state.subNavId,
    contextTimeout: state.contextTimeout,
    setIsMobileNavOpen: (value) => {
      dispatch({ type: actions.SET_ISMOBILENAVOPEN, value })
    },
    setSubNavId: (value) => {
      dispatch({ type: actions.SET_SUBNAVID, value })
    },
    setContextTimeout: (value) => {     
      dispatch({ type: actions.SET_CONTEXTTIMEOUT, value })
    },
  }

  return <MobileNavContext.Provider value={value}>{children}</MobileNavContext.Provider>
}

export default MobileNavContext
