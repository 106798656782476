import { v4 as uuid } from 'uuid'
import { getGeolocationCountry } from './backendApiService'
import { getInsecureValue, setInsecureValue } from './insecureStore'

let getGeolocationPromise = undefined

export const geolocationKey = 'geolocation'
const defaultCountry = 'GB'

export function currentGeolocation() {
  return getInsecureValue(geolocationKey)
}

export function getGeolocation(callingCountries) {
  if (getGeolocationPromise === undefined) {
    getGeolocationPromise = new Promise((resolve) => {
      const currentGeolocation = getInsecureValue(geolocationKey)
      if (hasGeolocationExpired(currentGeolocation)) {
        getGeolocationCountry(uuid())
          .then((data) => {
            setGeolocation(callingCountries, data)
            resolve(getInsecureValue(geolocationKey))
          })
          .catch((error) => {
            setGeolocation(callingCountries, undefined)
            resolve(getInsecureValue(geolocationKey))
          })
          .finally(() => {
            getGeolocationPromise = undefined
          })
      } else {
        resolve(currentGeolocation)
        getGeolocationPromise = undefined
      }
    })
  }

  return getGeolocationPromise
}

function hasGeolocationExpired(geolocation) {
  if (
    geolocation === null ||
    geolocation === undefined ||
    geolocation['country'] === undefined ||
    geolocation['expires'] === undefined
  ) {
    return true
  }

  const date = new Date()
  const expiryDate = new Date(geolocation['expires'])

  return expiryDate < date
}

export function setGeolocation(callingCountries, data) {
  if (data === undefined) {
    const country = resolveCountryFromBrowserOrDefault()
    setInsecureValue(geolocationKey, {
      country: country,
      callingCountry: resolveCallingCountry(callingCountries, country),
      expires: getExpiryDateTime(24),
    })
  } else {
    setInsecureValue(geolocationKey, {
      country: data.country,
      callingCountry: resolveCallingCountry(callingCountries, data.country),
      expires: getExpiryDateTime(24),
    })
  }
}

function getExpiryDateTime(hours) {
  let date = new Date()
  date.setTime(date.getTime() + hours * 60 * 60 * 1000)
  return date
}

function resolveCallingCountry(callingCountries, country) {
  let callingCountry = callingCountries[country]

  if (callingCountry === undefined) {
    callingCountry = 'uk'
  }

  return callingCountry
}

function resolveCountryFromBrowserOrDefault() {
  const languages = typeof navigator !== 'undefined' ? navigator.languages : undefined
  let country = defaultCountry

  try {
    if (languages !== null && languages !== undefined && languages.length > 0) {
      const browserCountry = languages[0].substring(3)

      if (browserCountry && browserCountry.length === 2) {
        country = browserCountry
      }
    }
  } catch (error) {}

  return country
}

export const usStatesArray = [
  { key: 'AK', value: 'Alaska' },
  { key: 'AL', value: 'Alabama' },
  { key: 'AR', value: 'Arkansas' },
  { key: 'AZ', value: 'Arizona' },
  { key: 'CA', value: 'California' },
  { key: 'CO', value: 'Colorado' },
  { key: 'CT', value: 'Connecticut' },
  { key: 'DC', value: 'District of Columbia' },
  { key: 'DE', value: 'Delaware' },
  { key: 'FL', value: 'Florida' },
  { key: 'GA', value: 'Georgia' },
  { key: 'HI', value: 'Hawaii' },
  { key: 'IA', value: 'Iowa' },
  { key: 'ID', value: 'Idaho' },
  { key: 'IL', value: 'Illinois' },
  { key: 'IN', value: 'Indiana' },
  { key: 'KS', value: 'Kansas' },
  { key: 'KY', value: 'Kentucky' },
  { key: 'LA', value: 'Louisiana' },
  { key: 'MA', value: 'Massachusetts' },
  { key: 'MD', value: 'Maryland' },
  { key: 'ME', value: 'Maine' },
  { key: 'MI', value: 'Michigan' },
  { key: 'MN', value: 'Minnesota' },
  { key: 'MO', value: 'Missouri' },
  { key: 'MS', value: 'Mississippi' },
  { key: 'MT', value: 'Montana' },
  { key: 'NC', value: 'North Carolina' },
  { key: 'ND', value: 'North Dakota' },
  { key: 'NE', value: 'Nebraska' },
  { key: 'NH', value: 'New Hampshire' },
  { key: 'NJ', value: 'New Jersey' },
  { key: 'NM', value: 'New Mexico' },
  { key: 'NV', value: 'Nevada' },
  { key: 'NY', value: 'New York' },
  { key: 'OH', value: 'Ohio' },
  { key: 'OK', value: 'Oklahoma' },
  { key: 'OR', value: 'Oregon' },
  { key: 'PA', value: 'Pennsylvania' },
  { key: 'RI', value: 'Rhode Island' },
  { key: 'SC', value: 'South Carolina' },
  { key: 'SD', value: 'South Dakota' },
  { key: 'TN', value: 'Tennessee' },
  { key: 'TX', value: 'Texas' },
  { key: 'UT', value: 'Utah' },
  { key: 'VA', value: 'Virginia' },
  { key: 'VT', value: 'Vermont' },
  { key: 'WA', value: 'Washington' },
  { key: 'WI', value: 'Wisconsin' },
  { key: 'WV', value: 'West Virginia' },
  { key: 'WY', value: 'Wyoming' },
]
