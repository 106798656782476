import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './src/components/global.css'
import { BasketProvider } from './src/contexts/basketContext'
import { GeolocationProvider } from './src/contexts/geolocationContext'
import { LanguageProvider } from './src/contexts/languageContext'
import { UserProvider } from './src/contexts/userContext'
import { SearchProvider } from './src/contexts/searchContext'
import { NotificationsProvider } from './src/contexts/notificationsContext'
import { LiveChatProvider } from './src/contexts/liveChatContext'
import { MobileNavProvider } from './src/contexts/mobileNavContext'
import { CurrencyProvider } from './src/contexts/currencyContext'
import { JobProvider } from './src/contexts/jobContext'
import { ConfiguratorProvider } from './src/contexts/newConfiguratorContext'
import { DataLayerProvider } from './src/contexts/dataLayerContext'

export const wrapRootElement = ({ element }) => (
  <NotificationsProvider>
    <GeolocationProvider>
      <LanguageProvider>
        <UserProvider>
          <BasketProvider>
            <SearchProvider>
              <CurrencyProvider>
                <JobProvider>
                  <LiveChatProvider>
                    <DataLayerProvider>
                      <ConfiguratorProvider>
                        <MobileNavProvider>{element}</MobileNavProvider>
                      </ConfiguratorProvider>
                    </DataLayerProvider>
                  </LiveChatProvider>
                </JobProvider>
              </CurrencyProvider>
            </SearchProvider>
          </BasketProvider>
        </UserProvider>
      </LanguageProvider>
    </GeolocationProvider>
  </NotificationsProvider>
)

export const onClientEntry = () => {
  const { ___loader: loader, pagePathOverride } = window

  if (!loader) {
    return
  }

  if (pagePathOverride) {
    const originalLoadPage = loader.loadPage
    const originalLoadPageSync = loader.loadPageSync

    // here we send window.pagePathOverride to loadPage / loadPageSync instead of window.location.pathname, which lets us override the page resources to use
    // and sets matchPath so the page hydrates correctly (https://github.com/gatsbyjs/gatsby/blob/6d8f0bfdcc1a2fe2d07f03583b19ea7934b0ccf4/packages/gatsby/cache-dir/production-app.js#L109-L117)
    loader.loadPage = async () => {
      const pageResources = await originalLoadPage(pagePathOverride)
      pageResources.page.matchPath = '*'
      return pageResources
    }
    loader.loadPageSync = () => {
      const pageResources = originalLoadPageSync(pagePathOverride)
      pageResources.page.matchPath = '*'
      return pageResources
    }
  }

  // We need to catch the GclId on initial load as this will be lost if the user navigates within the application. We'll hold on
  // to this for the lifetime of the session.
  const urlParams = new URLSearchParams(window.location.search)
  const gclId = urlParams.get('gclid')
  if (gclId) {
    sessionStorage.setItem('gclid', gclId)
  }
}
