import React from 'react'

const initialState = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  accountType: undefined,
  country: undefined,
  queryingUser: true,
  canCheckout: false,
  pAndASearchFeatureEnabled: false,
  isDistributor: false,
  organisationId: undefined,
  accountNumber: undefined,
  allowPromoCodes: false,
  allowCertificateOfConformity: false,
  invoicesFeatureEnabled: false,
  quotesFeatureEnabled: false,
}

const actions = {
  SET_EMAIL: 'SET_EMAIL',
  SET_FIRSTNAME: 'SET_FIRSTNAME',
  SET_LASTNAME: 'SET_LASTNAME',
  SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_QUERYINGUSER: 'SET_QUERYINGUSER',
  SET_CANCHECKOUT: 'SET_CANCHECKOUT',
  SET_PANDASEARCHFEATUREENABLED: 'SET_PANDASEARCHFEATUREENABLED',
  SET_ISDISTRIBUTOR: 'SET_ISDISTRIBUTOR',
  SET_ORGANISATONID: 'SET_ORGANISATIONID',
  SET_ACCOUNT_NUMBER: 'SET_ACCOUNT_NUMBER',
  SET_ALLOWPROMOCODES: 'SET_ALLOWPROMOCODES',
  SET_ALLOWCERTIFICATEOFCONFORMITY: 'SET_ALLOWCERTIFICATEOFCONFORMITY',
  SET_INVOICESFEATUREENABLED: 'SET_INVOICESFEATUREENABLED',
  SET_QUOTESFEATUREENABLED: 'SET_QUOTESFEATUREENABLED',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_EMAIL:
      return { ...state, email: action.value }
    case actions.SET_FIRSTNAME:
      return { ...state, firstName: action.value }
    case actions.SET_LASTNAME:
      return { ...state, lastName: action.value }
    case actions.SET_ACCOUNT_TYPE:
      return { ...state, accountType: action.value }
    case actions.SET_COUNTRY:
      return { ...state, country: action.value }
    case actions.SET_QUERYINGUSER:
      return { ...state, queryingUser: action.value }
    case actions.SET_CANCHECKOUT:
      return { ...state, canCheckout: action.value }
    case actions.SET_PANDASEARCHFEATUREENABLED:
      return { ...state, pAndASearchFeatureEnabled: action.value }
    case actions.SET_ISDISTRIBUTOR:
      return { ...state, isDistributor: action.value }
    case actions.SET_ORGANISATONID:
      return { ...state, organisationId: action.value }
    case actions.SET_ACCOUNT_NUMBER:
      return { ...state, accountNumber: action.value }
    case actions.SET_ALLOWPROMOCODES:
      return { ...state, allowPromoCodes: action.value }
    case actions.SET_ALLOWCERTIFICATEOFCONFORMITY:
      return { ...state, allowCertificateOfConformity: action.value }
    case actions.SET_INVOICESFEATUREENABLED:
      return { ...state, invoicesFeatureEnabled: action.value }
    case actions.SET_QUOTESFEATUREENABLED:
      return { ...state, quotesFeatureEnabled: action.value }
    default:
      return state
  }
}

const UserContext = React.createContext(initialState)

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    email: state.email,
    firstName: state.firstName,
    lastName: state.lastName,
    accountType: state.accountType,
    country: state.country,
    queryingUser: state.queryingUser,
    canCheckout: state.canCheckout,
    pAndASearchFeatureEnabled: state.pAndASearchFeatureEnabled,
    isDistributor: state.isDistributor,
    organisationId: state.organisationId,
    accountNumber: state.accountNumber,
    allowPromoCodes: state.allowPromoCodes,
    allowCertificateOfConformity: state.allowCertificateOfConformity,
    invoicesFeatureEnabled: state.invoicesFeatureEnabled,
    quotesFeatureEnabled: state.quotesFeatureEnabled,
    setEmail: (value) => {
      dispatch({ type: actions.SET_EMAIL, value })
    },
    setFirstName: (value) => {
      dispatch({ type: actions.SET_FIRSTNAME, value })
    },
    setLastName: (value) => {
      dispatch({ type: actions.SET_LASTNAME, value })
    },
    setAccountType: (value) => {
      dispatch({ type: actions.SET_ACCOUNT_TYPE, value })
    },
    setCountry: (value) => {
      dispatch({ type: actions.SET_COUNTRY, value })
    },
    setQueryingUser: (value) => {
      dispatch({ type: actions.SET_QUERYINGUSER, value })
    },
    setCanCheckout: (value) => {
      dispatch({ type: actions.SET_CANCHECKOUT, value })
    },
    setPAndASearchFeatureEnabled: (value) => {
      dispatch({ type: actions.SET_PANDASEARCHFEATUREENABLED, value })
    },
    setIsDistributor: (value) => {
      dispatch({ type: actions.SET_ISDISTRIBUTOR, value })
    },
    setOrganisationId: (value) => {
      dispatch({ type: actions.SET_ORGANISATONID, value })
    },
    setAccountNumber: (value) => {
      dispatch({ type: actions.SET_ACCOUNT_NUMBER, value })
    },
    setAllowPromoCodes: (value) => {
      dispatch({ type: actions.SET_ALLOWPROMOCODES, value })
    },
    setAllowCertificateOfConformity: (value) => {
      dispatch({ type: actions.SET_ALLOWCERTIFICATEOFCONFORMITY, value })
    },
    setInvoicesFeatureEnabled: (value) => {
      dispatch({ type: actions.SET_INVOICESFEATUREENABLED, value })
    },
    setQuotesFeatureEnabled: (value) => {
      dispatch({ type: actions.SET_QUOTESFEATUREENABLED, value })
    },
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext
