import React, { createContext } from 'react'

const initialState = {
  productSuggestions: undefined,
  datasheetSuggestions: undefined,
  partNumberSuggestions: undefined,
  categoriesSuggestions: undefined,
  contentSuggestions: undefined,
  showSuggestions: false,
  suggestionsOpen: false,
  searchValue: ''
}

const actions = {
  SETPRODUCTSUGGESTIONS: 'SESETPRODUCTSUGGESTIONST',
  SETDATASHEETSUGGESTIONS: 'SETDATASHEETSUGGESTIONS',
  SETPARTNUMBERSUGGESTIONS: 'SETPARTNUMBERSUGGESTIONS',
  SETCATEGORIESSUGGESTIONS: 'SETCATEGORIESSUGGESTIONS',
  SETCONTENTSUGGESTIONS: 'SETCONTENTSUGGESTIONS',
  SETSHOWSUGGESTIONS: 'SETSHOWSUGGESTIONS',
  SETSUGGESTIONSOPEN: 'SETSUGGESTIONSOPEN',
  SETSEARCHVALUE: 'SETSEARCHVALUE',
  CLOSESUGGESTIONS: 'CLOSESUGGESTIONS',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SETPRODUCTSUGGESTIONS:
      return { ...state, productSuggestions: action.value }
    case actions.SETDATASHEETSUGGESTIONS:
      return { ...state, datasheetSuggestions: action.value }
    case actions.SETPARTNUMBERSUGGESTIONS:
      return { ...state, partNumberSuggestions: action.value }
    case actions.SETCATEGORIESSUGGESTIONS:
      return { ...state, categoriesSuggestions: action.value }
    case actions.SETCONTENTSUGGESTIONS:
      return { ...state, contentSuggestions: action.value }
    case actions.SETSHOWSUGGESTIONS:
      return { ...state, showSuggestions: action.value }
    case actions.SETSUGGESTIONSOPEN:
      return { ...state, suggestionsOpen: action.value }
    case actions.SETSEARCHVALUE:
      return { ...state, searchValue: action.value }
    case actions.CLOSESUGGESTIONS:
      return { ...state, productSuggestions: [],
        datasheetSuggestions: [],
        partNumberSuggestions: [],
        categoriesSuggestions: [],
        contentSuggestions: [],
        showSuggestions: false,
        suggestionsOpen: false }
    default:
      return state
  }
}

const SearchContext = createContext(initialState)

export const SearchProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    productSuggestions: state.productSuggestions,
    datasheetSuggestions: state.datasheetSuggestions,
    partNumberSuggestions: state.partNumberSuggestions,
    categoriesSuggestions: state.categoriesSuggestions,
    contentSuggestions: state.contentSuggestions,
    showSuggestions: state.showSuggestions,
    suggestionsOpen: state.suggestionsOpen,
    searchValue: state.searchValue,
    setProductSuggestions: (value) => {
      dispatch({ type: actions.SETPRODUCTSUGGESTIONS, value })
    },
    setDatasheetSuggestions: (value) => {
      dispatch({ type: actions.SETDATASHEETSUGGESTIONS, value })
    },
    setPartNumberSuggestions: (value) => {
      dispatch({ type: actions.SETPARTNUMBERSUGGESTIONS, value })
    },
    setCategoriesSuggestions: (value) => {
      dispatch({ type: actions.SETCATEGORIESSUGGESTIONS, value })
    },
    setContentSuggestions: (value) => {
      dispatch({ type: actions.SETCONTENTSUGGESTIONS, value })
    },
    setShowSuggestions: (value) => {
      dispatch({ type: actions.SETSHOWSUGGESTIONS, value })
    },
    setSuggestionsOpen: (value) => {
      dispatch({ type: actions.SETSUGGESTIONSOPEN, value })
    },
    setSearchValue: (value) => {
      dispatch({ type: actions.SETSEARCHVALUE, value })
    },
    closeSuggestions: () => {
      dispatch({ type: actions.CLOSESUGGESTIONS, undefined })
    }
  }

  return (<SearchContext.Provider value={value}>{children}</SearchContext.Provider>)
}

export default SearchContext
