import React from 'react'
import { v4 as uuid } from 'uuid'

const initialState = {
  notifications: [],
}

const actions = {
  SET: 'SET',
  DELETE: 'DELETE',
  CLEAR: 'CLEAR',
}

export const NotificationLevel = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET: {
      return { ...state, notifications: [...state.notifications, action.value] }
    }
    case actions.DELETE: {
      let update = false
      let notifications = []

      for (var index = 0; index < state.notifications.length; index++) {
        const notification = state.notifications[index]
        if (notification.id === action.value) {
          update = true
        } else {
          notifications.push(notification)
        }
      }

      if (update === true) {
        return { ...state, notifications: notifications }
      } else {
        return state
      }
    }
    case actions.CLEAR:
      return { ...state, notifications: [] }
    default:
      return state
  }
}

const NotificationsContext = React.createContext(initialState)

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    notifications: state.notifications,
    setNotification: (value) => {
      dispatch({ type: actions.SET, value })
    },
    deleteNotification: (value) => {
      dispatch({ type: actions.DELETE, value })
    },
    clearNotifications: (value) => {
      dispatch({ type: actions.CLEAR, value })
    },
  }

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}

export default NotificationsContext

const defaultTTL = 6000
const defaultTTLWithLink = 10000

export const publishDismissableError = (id, error, setNotificationCallback) =>
  publishError(id, error, defaultTTL, setNotificationCallback, null)
export const publishDismissableWarning = (id, warning, setNotificationCallback) =>
  publishWarning(id, warning, defaultTTL, setNotificationCallback, null)
export const publishDismissableSuccess = (id, success, setNotificationCallback) =>
  publishSuccess(id, success, defaultTTL, setNotificationCallback, null)
export const publishPermanentDismissableError = (id, error, setNotificationCallback) =>
  publishError(id, error, null, setNotificationCallback, null)
export const publishPermanentDismissableWarning = (id, warning, setNotificationCallback) =>
  publishWarning(id, warning, null, setNotificationCallback, null)
export const publishPermanentDismissableSuccess = (id, success, setNotificationCallback) =>
  publishSuccess(id, success, null, setNotificationCallback, null)

export const publishDismissableWarningWithLink = (id, warning, setNotificationCallback, link) =>
  publishWarning(id, warning, defaultTTLWithLink, setNotificationCallback, link)
export const publishDismissableSuccessWithLink = (id, success, setNotificationCallback, link) =>
  publishSuccess(id, success, defaultTTLWithLink, setNotificationCallback, link)

export const publishError = (id, error, dismissAfter, setNotificationCallback, link) => {
  const idToUse = id !== undefined && id !== null ? id : uuid()
  setNotificationCallback({
    id: idToUse,
    message: error,
    dismissAfter: dismissAfter,
    level: NotificationLevel.ERROR,
    expireOn: getExpiryDateTime(dismissAfter),
    link: link
  })
  return idToUse
}

export const publishWarning = (id, warning, dismissAfter, setNotificationCallback, link) => {
  const idToUse = id !== undefined && id !== null ? id : uuid()
  setNotificationCallback({
    id: idToUse,
    message: warning,
    dismissAfter: dismissAfter,
    level: NotificationLevel.WARNING,
    expireOn: getExpiryDateTime(dismissAfter),
    link: link
  })
  return idToUse
}

export const publishSuccess = (id, success, dismissAfter, setNotificationCallback, link) => {
  const idToUse = id !== undefined && id !== null ? id : uuid()
  setNotificationCallback({
    id: idToUse,
    message: success,
    dismissAfter: dismissAfter,
    level: NotificationLevel.SUCCESS,
    expireOn: getExpiryDateTime(dismissAfter),
    link: link
  })
  return idToUse
}

export const unpublishNotification = (id, deleteNotificationCallback) => {
  deleteNotificationCallback(id)
}

function getExpiryDateTime(milliseconds) {
  if (milliseconds !== null) {
    let date = new Date()
    date.setTime(date.getTime() + milliseconds)
    return date
  } else {
    return null
  }
}
