export const xsrfTokenKey = 'XSRF-TOKEN'

export function getCookie(key) {
  if (typeof document !== 'undefined') {
    var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : undefined
  }
}

export function setCookie(key, value) {
  if (typeof document !== 'undefined') {
    const cookie = `${key}=${value};`
    document.cookie = cookie
  }
}

export function deleteCookie(key) {
  if (typeof document !== 'undefined') {
    const cookie = `${key}=;`
    document.cookie = cookie
  }
}
