import React from 'react'

const initialState = {
  validCurrencies: undefined,
}

const actions = {
  SET_VALID_CURRENCIES: 'SET_VALID_CURRENCIES',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_VALID_CURRENCIES: {
      let newState = { ...state, validCurrencies: action.value }

      return newState
    }
    default:
      return state
  }
}

const CurrencyContext = React.createContext(initialState)

export const CurrencyProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    validCurrencies: state.validCurrencies,
    setValidCurrencies: (value) => {
      dispatch({ type: actions.SET_VALID_CURRENCIES, value })
    },
  }

  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>
}

export default CurrencyContext
