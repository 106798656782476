exports.supportedLocales = getLanguages()

function getLanguages() {
  switch (process.env.GATSBY_FEATURE_ENVIRONMENT) {
    case 'dev':
      return JSON.stringify(['en', 'de', 'nl', 'fr', 'it', 'es', 'en-US', 'pl', 'cs', 'pt'])

    case 'pentest':
      return JSON.stringify(['en'])

    case 'test':
      return JSON.stringify(['en', 'de', 'nl', 'fr', 'it', 'es', 'en-US', 'pl', 'cs', 'pt'])

    case 'prod':

    default:
      return JSON.stringify(['en', 'de', 'nl', 'fr', 'it', 'es', 'en-US', 'pl', 'cs', 'pt'])
  }
}